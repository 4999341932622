<template>
    <div @click="viewImage" class="media-content">
        <div
            class="border border-dark rounded image-card-dim center-horizontal p-1"
        >
            <!-- <div class="row image-trim">
                <img v-if="useImgTag" :src="imgUrl" alt="image" class="media-content image-trim">
                <video v-else :src="preloadUrl" controls playsinline class="media-content image-trim"></video>
            </div> -->
            <div v-if="useImgTag">
                <img v-if="monoImage" :src="imgUrl" alt="image" width="100%">
                <img v-else :src="imgUrl" alt="image" class="image-fit">
            </div>
            <div v-else>
                <video :src="preloadUrl" alt="video" class="video-fit" controls playsinline></video>
            </div>
        </div>

        
       
    </div>
</template>

<script>

export default {
    props: {
        imgUrl: {type: String, default: "#"},
        contentType: null,
        monoImage: {type: Boolean,},
    },
    mounted() {
        console.log(this.contentType);
        console.log(this.imgUrl);
    },
    computed: {
        useImgTag: function() {
            return !this.contentType.includes("video");
        },
        preloadUrl() {
            // ensures thumbnail load on iOS browsers
            return `${this.imgUrl}#t=0.001`;
        }
    },
    methods: {
        viewImage() { 
            // only view the image if it is not a video
            if (this.useImgTag) {
                this.$emit("viewImage", this.imgUrl);
            }
        }
    }
}
</script>

<style scoped>

    .center-text {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .center-horizontal {
        display: flex;
        justify-content: center;
        align-items: center;
    }



    .image-fit {
        height: 20rem;
        object-fit: cover;
    }

    .video-fit {
        height: 19.6rem;
        width: auto;
    }


    .image-card-dim {
        width: 40;
    }

    .media-content {
        height: 100%;
        background-color: white;
    }

</style>