<template>
    <div class="expanded-viewport">
        <AppbarFixed :hasBackButton="true" />
        <b-container>
            <b-row class="p-3" v-for="(img, index) in imgUrls" :key="index">
                <b-col >
                    <ImageCarouselCard :imgUrl="img" :contentType="contentTypes[index]" @viewImage="viewImage"/>
                </b-col>
            </b-row>
        </b-container>
        
    </div>
</template>

<script>
import AppbarFixed from '../../components/general/AppbarFixed.vue';
import ImageCarouselCard from '../../components/project_view/nested/ImageCarouselCard.vue';

import { mapState } from 'vuex';

export default {
    components: {
        ImageCarouselCard,
        AppbarFixed,
    },
    data() {
        return {
            imgUrls: {type: [String], default: () => []},
            contentTypes: {type: [String], default: () => []}
        }
    },
    methods: {
        viewImage(url) {
            this.$router.push({name: "View Project Image", params: {url: url}});
        },
    },
    computed: {
        ...mapState({
            temporaryGalleryImgs: (state) => state.temporaryGalleryImgs,
            temporaryGalleryContentTypes: (state) => state.temporaryGalleryContentTypes,
        }),
    },
    created() {
        this.imgUrls = this.$route.params.imgUrls;
        this.contentTypes = this.$route.params.contentTypes;
        if (this.imgUrls == null && this.contentTypes == null) {
            console.log("null img urls, pull from backup");
            // this.$store.commit("updateTemporaryGallery", null, null);
            this.imgUrls = this.temporaryGalleryImgs;
            this.contentTypes = this.temporaryGalleryContentTypes;
        } else {
            console.log("we should be good, updating the temporary cache");
            this.$store.commit("updateTemporaryGallery", {imgUrls: this.imgUrls, contentTypes: this.contentTypes});
        }
    }
}
</script>

<style lang="scss" scoped>

</style>